@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'SFTSchriftedSans';
  min-height: 100vh;
  background-color: #fff;
}
.swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(169, 169, 169);
}
.position-initial{
  position: initial !important;
}
.swiper-pagination-bullet-active{
  background: var(--swiper-pagination-color, #fff);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
}
.red-boarder{
  background-color: #c3c3c3;
  color: rgb(98, 98, 98);
}
.flex-col{
  display: flex;
  flex-direction: column;
  gap:7px;
}
.btn-comment{
  box-sizing: border-box;
  border-radius: 10px;
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 300 !important;
  line-height: 3px;
  padding-top: 5px;
  cursor: pointer;
}
.op-5{
  opacity: 0.5;
}
.carusel-my{
  width: 100% ;
}
.mySwiper{
  max-height: 50vh;
  border-radius: 30px;
  position: relative;
}
.swiper-pagination-fraction{
  padding: 8px 24px;
  background: rgb(60, 60, 60);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 90px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  height: -moz-fit-content;
  height: fit-content;
  width: 100px;
  position: absolute;
  left: calc(100% - 130px);
  bottom: 29px;
}
.cart{
  position: absolute;
  right:20px;
}

.sold-out{
  background-color: #ffe6e6;
  border-radius: 10px;
  text-align: start;
  padding: 5px 15px;
  color: #351212;
  min-height: 50px;
  align-items: center;
  display: flex;
}