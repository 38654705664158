.spinner {
    width:50px;
    height:50px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#000000);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}