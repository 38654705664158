@font-face {
   font-family: 'SFTSchriftedSans';
   src: url('./fonts/SFTSchriftedSansTRIAL-Regular.woff2') format('woff2');
   font-size: normal;
   font-weight: 400;
}

@font-face {
   font-family: 'SFTSchriftedSans';
   src: url('./fonts/SFTSchriftedSansTRIAL-Medium.woff2') format('woff2');
   font-size: normal;
   font-weight: 500;
}

@font-face {
   font-family: 'SFTSchriftedSans';
   src: url('./fonts/SFTSchriftedSansTRIAL-Bold.woff2') format('woff2');
   font-size: normal;
   font-weight: 700;
}

@font-face {
   font-family: 'Mulish';
   src: url('./fonts/Mulish-SemiBold.woff2') format('woff2');
   font-size: normal;
   font-weight: 600;
}

@font-face {
   font-family: 'Montserrat';
   src: url('./fonts/Montserrat-Bold.woff2') format('woff2');
   font-size: normal;
   font-weight: 700;
}

@font-face {
   font-family: 'Roboto';
   src: url('./fonts/Roboto-Medium.woff2') format('woff2');
   font-size: normal;
   font-weight: 500;
}

:root {
   --max-midth-content: 680px;
}
 
body {
   font-weight: 400;
   background: #FFF;
   overflow-x: hidden;
   height: auto;
}

.container-screen {
   width: 100%;
   max-width: var(--max-midth-content)
}

.header {
   width: 100vw;
   position:fixed;
   top: 0;
   margin: 0 auto;
   display: flex;
   align-items: center;
   padding: 26px 21px;
   justify-content: space-between;
   gap: 24px;
   background: #fff;
   z-index: 3;
}

.header__link-logo {
   display: flex;
   height: auto;
   justify-content: center;
}

/* screen-1 */
.screen-1 {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100svh;
   padding-top: 80px;
}

.screen-1 .header {
   padding: 16px 29px;
}

.screen-1-wrapper {
   max-width: var(--max-midth-content);
   width:90vw;
   display: flex;
   flex-direction: column;
   height: 100%;
}

.header__link-logo {
   width: 100%;
}

.header__link-logo img {
   width: 100%;
   max-width: 228px;
}

.thing {
   display: flex;
   flex-direction: column;
   gap: 15px;
   flex: 1;
}

.thing_img-wrapper {
   position: relative;
   display: flex;
   justify-content: center;
   border-radius: 30px;
   overflow: hidden;
   flex: 1;
   width: 100vw;
   max-width: var(--max-midth-content);
}

.thing_img-wrapper__img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.slider_buttoms {
   position: absolute;
   bottom: 20px;
   display: flex;
   gap: 5px;
}

.slider_buttoms__item {
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background: rgba(255, 255, 255, 0.3);
}

.slider_buttoms__active {
   background: rgba(255, 255, 255, 0.72);
}
.footer{
   opacity: 0.3;
   display: flex;
   margin-top: 20px;
   padding-bottom: 100px;
   font-size: 12px;;
}
.footer div{
   width:50%;

}
.thing_data {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.thing_data-wrapper {
   display: flex;
   flex-direction: column;
   gap: 2px;
}

.thing_data__title {
   font-weight: 500;
   font-size: 18px;
   line-height: 21px;
   padding-right: 10px;
   color: #000000;
}
.link{
   font-weight: 500;
   text-decoration: underline;
}
.thing_data__description {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #CFCFCF;
}

.thing_data-price {
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   color: #000000;
}

.img-wrapper {
   width: 100%;
   margin-top: 52px;
   display: flex;
   border-radius: 30px 30px 0 0;
   overflow: hidden;
}

.img-wrapper__img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

/* screen-1 END */

/* screen-2 */
.screen-2 {
   width: 100%;
   display: flex;
   justify-content: center;
   min-height: 100vh;
   padding-bottom: 100px;
}

.img-container {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: center;
   max-height: 38.9%;
   outline: 1px solid #f2f2f2;
   border-radius: 0px 0px 20px 20px;
   overflow: hidden;
}

.img-container__img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.back-btn {
   position: absolute;
   top: 42px;
   left: 21px;
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background: rgb(206, 206, 206);
}
.coursel-div-list{
   width: 100%;
   background-size: cover;
   background-position: center;
   margin-bottom: -1px;
   aspect-ratio: 1/1.221;
   position: relative;
}

.basket {
   position: absolute;
   top: 42px;
   right: 21px;
   width: 56px;
   height: 56px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   -webkit-backdrop-filter: blur(7.7px);
   background: rgb(206, 206, 206);
   backdrop-filter: blur(7.7px);
}

.size-box {
   position: absolute;
   bottom: 31px;
   padding-top: 35px;
   width: calc(100% - 42px);
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.size-box__title {
   position: absolute;
   top: 0;
   left: 0;
   font-family: 'Mulish';
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   color: #868686;
}

.size-box_sizes {
   display: flex;
   gap: 10px;
}

.size-box_sizes__item {
   width: 38px;
   height: 38px;
   border: 1px solid #868686;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 16px;
   line-height: 20px;
   color: #868686;
}

.size-box_sizes__item-active {
   color: #fff;
   background: #2727278d;
   border: 1px solid #727272;

}

.img-container_count {
   padding: 8px 24px;
   background: rgba(255, 255, 255, 0.22);
   backdrop-filter: blur(2px);
   border-radius: 90px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #FFFFFF;
   height: fit-content;
}

.product-wrapper {
   padding: 32px 36px 20px 21px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 51.9%;
}

.product_title {
   display: flex;
   flex-direction: column;
   gap: 5px;
   padding-bottom: 10px;
   /* padding-bottom: 33px; */
}

.product_title__name {
   font-weight: 500;
   font-size: 32px;
   line-height: 38px;
   color: #000000;
}

.product_title__text {
   font-weight: 400;
   font-size: 22px;
   line-height: 26px;
   color: #B8B8B8;
}

.product_description {
   position: relative;
   /* padding: 45px 0; */
   padding: 10px 0;
   display: flex;
   justify-content: center;
}

.screen-separator {
   width: 100%;
   display: flex;
   justify-content: center;
}

.screen-separator::before {
   content: "";
   width: 100%;
   max-width: 178px;
   height: 1px;
   background-color: #F0F0F0;
}

.product_description__text {
   width: 100%;
   text-align: start;
}
.toCart{
   text-align: end;
   width: 100%;
   margin-top: 15px;
}
.cartbullet{
   position: absolute;
   top: -5px;
   right: -5px;
   background-color: #ff1414;
   width:20px;
   height:20px;
   border-radius: 50%;
   /* border: 1px solid #fff; */
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
}
.buy-block {
   width: 100%;
   /* padding-top: 45px; */
   padding-top: 15px;
   display: flex;
   justify-content: space-between;
   align-items: start;
}

.buy-block__price {
   font-style: normal;
   font-weight: 500;
   font-size: 32px;
   line-height: 38px;
   color: #3D3D3D;
}

.buy-block_btns-wrapper {
   display: flex;
   gap: 7px;
   max-width: 60%;
}
.buy-block__btn-buy-more{
   width: 200px;
   height: 52px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid #3D3D3D;
   border-radius: 15px;
   font-weight: 500;
   font-size: 18px;
   line-height: 21px;
   color: #3D3D3D;
}

.buy-block__btn-buy {
   width: 200px;
   height: 52px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #3D3D3D;
   border-radius: 15px;
   font-weight: 500;
   font-size: 18px;
   line-height: 21px;
   color: #FFFFFF;
}

.buy-block__btn-basket {
   width: 52px;
   height: 52px;
   background: #FFFFFF;
}

/* screen-2 END */

/* screen-3 */
.screen-3 {
   width: 100%;
   display: flex;
   justify-content: center;
   height: 100svh;
}

.screen-3 .container-screen {
   display: flex;
   flex-direction: column;
}

.screen-3 .header {
   padding: 46px 21px;
   justify-content: space-between;
   gap: 24px;
}

.screen-3 .header__link-logo {
   width: auto;
}

.screen-3 .header__link-logo img {
   min-width: auto;
}

.screen-3 .img-container {
   height: 55.33%;
}

.screen-3 .product_title {
   padding: 0 21px 51px 21px;
   flex: 1;
   justify-content: flex-end;
}

.screen-3 .size-box {
   bottom: 41px;
   padding-top: 35px;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   flex-direction: column;
}

.screen-3 .buy-block {
   padding-top: 22px;
}

.screen-3 .buy-block__price {
   color: #fff;
}

.screen-3 .buy-block__btn-buy {
   background: rgba(255, 255, 255, 0.28);
}

/* screen-3 END */
.main-btn-back{
   padding: 18px 0;
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   border: 2px solid #3D3838;
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   color: #3D3838;
   font-family: 'SFTSchriftedSans';
}
.main-btn {
   padding: 18px 0;
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   background: #3D3838;
   border-radius: 15px;
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   color: #FFFFFF;
   font-family: 'SFTSchriftedSans';
}

/* shopping-cart */

.shopping-cart {
   min-height: 100vh;
   max-width: var(--max-midth-content);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   overflow: hidden;
}

.shopping-cart .header {
   padding-bottom: 29px;
}

.products-list {
   width: 100%;
   padding: 0 8px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-bottom: 20px;
   margin-top: 110px;
}

.card-product-wrapper {
   position: relative;
   padding: 14px 19px;
   background: #FFFFFF;
   box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.05);
   border-radius: 20px;
   display: flex;
   gap: 18px;
   align-items: center;
   width: 100%;
   flex-shrink: 0;
}

.card-product {
   position: relative;
   display: flex;
   gap: 10px;
   transition: .3s;
   left: 0;
}

.card-shift {
   left: -78px;
}
.gap-5{
   gap: 5px;
   
}
.products-item__img {
   width: 106px;
   border: 1px solid #f2f2f2;
   border-radius: 13px;
   overflow: hidden;
   aspect-ratio: 1/1.22;
}

.products-item__img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.products-item-data {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.products-item-data__name {
   max-width: 208px;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.products-item-data__price {
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 14px;
   line-height: 15px;
   color: #C3C6C9;
}

.products-item-data__size {
   font-family: 'Mulish';
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
   color: #201A25;
}

.product_count {
   position: absolute;
   bottom: 13px;
   right: 11px;
   display: flex;
   align-items: center;
}
.small{
   transform: scale(0.5);
}
.line-cart-card{
   display: flex;
   align-items: center;
   justify-content: start;
   gap: 4px;
}
.line-cart-card strong{
   font-weight: 600;
   font-size: 11px;
}
.placeholder-cart{
   font-weight: 300;
   font-size: 10px;
   opacity: 0.5;
   padding-top: 10px;
   justify-content: end;
   align-items: end;
   color: #868686 !important;
}
.finish-go-cart{
   background: #EEF1F4;
   border-radius: 8px;
   padding: 10px 20px;
   width: 100%;
}

.product_btn__dec,
.product_btn__inc {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40px;
   height: 40px;
   background: #EEF1F4;
   border-radius: 8px;
}

.product_count__num {
   margin: 0 15px;
   font-family: 'Roboto';
   font-weight: 500;
   font-size: 20px;
   line-height: 23px;
   color: #C3C6C9;
}

.product-delete {
   display: flex;
   padding: 0 25px;
   align-items: center;
   justify-content: center;
   background: #E35C5C;
   border-radius: 15px;
}

.order-wrapper {
   width: 100%;
   padding: 0 20px;
   padding-bottom: 24px;
}

.order_price,
.order_delivery,
.order_total {
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.order_price {
   font-weight: 400;
   font-size: 18px;
   line-height: 21px;
   color: #000000;
}

.order_delivery {
   margin-top: 15px;
   font-weight: 400;
   font-size: 18px;
   line-height: 21px;
   color: #C3C6C9;
}

.order_total {
   margin-top: 20px;
   padding-top: 25px;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
   border-top: 1px solid #F0F0F0;
}
.oferta{
   font-size: 10px;
   font-weight: 300;
   color: #868686;
   margin-bottom: 20px;
   margin-top: 20px;
   background-color: #afafaf31;
   border-radius: 9px;
   padding: 10px 20px;
}
/* shopping-cartс END */

/* delivery */
.delivery {
   min-height: 100svh;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   width: 100%;
   max-width: var(--max-midth-content);
}

.delivery .header {
   padding-bottom: 33px;
}

.delivery-wrapper {
   width: 100%;
   padding: 0 20px;
   margin-bottom: 50px;
}

.delivery-address {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 33px;
   padding-bottom: 35px;
   border-bottom: 1px solid #F0F0F0;
}

.delivery-address_header {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.delivery__title {
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.delivery-address_data {
   display: flex;
   gap: 13px;
   align-items: center;
}

.delivery-address_data__block {
   width: 57px;
   height: 57px;
   background: #F8F8F8;
   border-radius: 50%;
}

.delivery-address_data-text {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.delivery-address_data-text__city {
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.delivery-address_data-text__address {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.delivery-recipient {
   margin-top: 28px;
}

.delivery-recipient__input {
   width: 100%;
   height: 62px;
   background: #F8F8F8;
   border-radius: 15px;
   font-weight: 400;
   font-size: 18px;
   line-height: 21px;
   padding-left: 20px;
}

.recipient-name {
   margin-top: 35px;
}

.recipient-phone {
   margin-top: 9px;
}

.delivery-recipient__input::placeholder {
   color: #C1C1C1;
}

.screen_btn-box {
   flex: 1;
   width: 100%;
   display: flex;
   align-items: flex-end;
   padding: 0 21px;
   padding-bottom: 24px;
}

/* delivery END */

/* choice-delivery */

.choice-delivery {
   min-height: 100svh;
   max-width: var(--max-midth-content);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   overflow: hidden;
}

.sub-header {
   position: relative;
   width: 100%;
   padding: 67px 0 43px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.sub-header_btn-back {
   position: absolute;
   left: 21px;
}

.sub-header__text {
   font-weight: 600;
   font-size: 18px;
   line-height: 21px;
   color: #000000;
}

.select-address-list {
   width: 100%;
   padding: 0 8px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-bottom: 40px;
}

.address_item-wrapper {
   position: relative;
   display: flex;
   gap: 10px;
   transition: .3s;
   left: 0;
}

.address_item {
   position: relative;
   width: 100%;
   padding: 20px 18px;
   padding-left: 63px;
   background: #FFFFFF;
   box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.05);
   border-radius: 20px;
   display: flex;
   gap: 10px;
   align-items: flex-start;
   flex-shrink: 0;
   flex-direction: column;
}

.address_item__input {
   display: none;
}

.label {
   position: absolute;
   display: inline-block;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   border: 1px solid #C3C6C9;
   left: 18px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: .3s;
}

.label::before {
   content: "";
   width: 22px;
   height: 22px;
   border-radius: 50%;
   background: transparent;
}

.address_item__input:checked+.label {
   border: 1px solid #000;
}

.address_item__input:checked+.label::before {
   background: #000000;
}

.address_item__city {
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.address_item__location {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.address-shift {
   left: -78px;
}

.address-delete {
   display: flex;
   padding: 0 25px;
   align-items: center;
   justify-content: center;
   background: #E35C5C;
   border-radius: 15px;
}

.choice-delivery .screen_btn-box {
   flex-direction: column;
   justify-content: flex-end;
}

.btn-add-address {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 64px;
   height: 64px;
   background: #EEF1F4;
   border-radius: 50%;
   margin-bottom: 34px;
   flex-shrink: 0;
   font-size: 24px;
}

/* choice-delivery END */


/* screen-order */

.screen-order {
   width: 100%;
   min-height: 100vh;
   max-width: var(--max-midth-content);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   overflow: hidden;
}

.order_products-list {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 20px 18px 31px 18px;
}

.order_products-list__item {
   display: flex;
   gap: 17px;
   align-items: center;
}

.order_products-list__item-img {
   display: flex;
   width: 77px;
   height: 77px;
   border-radius: 13px;
   overflow: hidden;
}
.product-image-list-courusel{
   border: 1px solid #f2f2f2;

}
.order_products-list__item-img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.order_products__data-wrapper {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.order_products__data-title {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.order_products__data-price {
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 14px;
   line-height: 15px;
   color: #C3C6C9;
}

.order_products__data-size {
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
   color: #201A25;
}

.screen-order_delivery {
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 25px 17px 0 17px;
   border-top: 1px solid #F0F0F0;
   background: #FFFFFF;
}

.screen-order_delivery__title {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.screen-order_delivery-wrapper {
   position: relative;
   margin-top: 20px;
   padding: 11px 9px 28px 11px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.05);
   border-radius: 0 0 15px 15px;
}

.screen-order_delivery__city {
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.screen-order_delivery__addres {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.screen-order_delivery__btn-change {
   position: absolute;
   top: 11px;
   right: 9px;
}

.screen-order .order-wrapper {
   margin-top: 31px;
}

.order-wrapper__title {
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
   margin-bottom: 20px;
}

.screen-order .order_total {
   margin-bottom: 64px;
}

/* screen-order END */
/* lk-order */
.lk-order {
   width: 100%;
   min-height: 100vh;
   max-width: var(--max-midth-content);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   padding-bottom: 35px
}

.lk-order_data {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: left;
   gap: 10px;
   padding: 13px 8px 0 8px;
}

.lk-order__title {
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}

.lk-order__text {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #B9B9B9;
}

.lk-order_product-list {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px 7px 0 8px;
}

.lk-order_product-list__item {
   display: flex;
   flex-direction: column;
   gap: 26px;
   padding: 25px 11px 20px 11px;
   background: #FFFFFF;
   box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.05);
   border-radius: 20px;
}

.lk-order_product-list__item-wrapper {
   display: flex;
   align-items: flex-end;
   justify-content: space-between
}

.lk-order_product-list__item-img {
   display: flex;
   width: 77px;
   height: 77px;
   border-radius: 13px;
   overflow: hidden;
}

.lk-order_product-list__item-img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.lk-order_product__data-wrapper {
   flex: 1;
   margin-left: 11px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.lk-order_product-list__status {
   width: 76px;
   height: 28px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #0E7E4D;
   border-radius: 23px;
   font-weight: 500;
   font-size: 14px;
   color: #FFFFFF;
}

.lk-order_product__data-title {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.lk-order_product__data-price {
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 14px;
   line-height: 15px;
   color: #C3C6C9;
}

.lk-order_product__data-size {
   font-family: 'Mulish';
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
   color: #201A25;
}

.lk-product_btns {
   display: flex;
   gap: 8px;
}

.lk-product_btns button {
   padding: 14px 0;
   display: flex;
   justify-content: center;
   flex: 1;
   font-weight: 500;
   font-size: 16px;
   border-radius: 10px;
   font-family: 'SFTSchriftedSans';
}

.btn-cancel-order {
   background: #3D3838;
   color: #FFFFFF;
}

.btn-details {
   background: #F8F8F8;
   color: #3D3838;
}

/* lk-order END */
/* information-order */
.information-order {
   width: 100%;
   min-height: 100vh;
   max-width: var(--max-midth-content);
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   padding-bottom: 35px
}

.information-order-wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 15px 7px 0 8px;
   align-items: flex-start;
}

.information-order_status {
   margin-top: 15px;
   height: 28px;
   padding: 0 18px;
   background: #2445C9;
   display: flex;
   align-items: center;
   border-radius: 23px;
   font-weight: 500;
   font-size: 14px;
   color: #FFFFFF;
}

.information-order_list {
   width: 100%;
   margin-top: 20px;
   padding: 13px;
   display: flex;
   gap: 15px;
   flex-direction: column;
   background: #F8F8F8;
   border-radius: 13px;
}

.information-order_list__title {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #B9B9B9;
}

.information-order_list-products {
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
}

.information-order_list-products__item {
   width: 47px;
   height: 47px;
   display: flex;
   border-radius: 5px;
   overflow: hidden;
}

.information-order_list-products__item img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.information-order__title {
   margin-top: 30px;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

/* information-order END*/

/* popup_screen */
.popup_screen {
   top: 0;
   position: fixed;
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 10;
}

.fon-blur {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   backdrop-filter: blur(2px);
   -webkit-backdrop-filter: blur(2px);
   background: rgba(0, 0, 0, 0.2);
}

.popup_screen-content {
   position: absolute;
   width: 364px;
   display: flex;
   flex-direction: column;
   padding: 81px 14px 17px 14px;
   background: #fff;
   border-radius: 25px;
   align-items: center;
   z-index: 1;
}

.popup_screen-ok .popup_screen-content {
   padding-top: 61px;
}

.popup_screen-ok .popup_screen-content__img {
   width: 85px;
}

.popup_screen-error .popup_screen-content__img {
   width: 112px;
}

.popup_screen-content__title {
   margin-top: 46px;
   font-weight: 600;
   font-size: 32px;
   line-height: 38px;
   color: #000000;
   text-align: center;
}

.popup_screen-ok .popup_screen-content__title {
   margin-top: 28px;
}

.popup_screen-content__text {
   margin-top: 15px;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
   margin-bottom: 46px;
   text-align: center;
}

.popup_screen-ok .popup_screen-content__text {
   margin-bottom: 34px;
}

@media screen and (max-width: 600px) {
   
   .max-70{
      max-width: 70%;
   }
   .w-content{
      width: fit-content;
   }
}
